<template>
  <div class="container">
    <div class="row card-panel">
      <div class="col m8 s8">
        <strong><span>Clarify:&nbsp;</span></strong>
        <span
          ><strong
            >{{ nameOfSubjectForViewQuestion }} /{{ nameOfTopicOfViewQuestion }}/
            {{ nameOfChapterOfViewQuestion }}</strong
          ></span
        >
        &nbsp;&nbsp;<br />
        <span>
          <strong
            >Question number: {{ questionNoOfTutorHelp }} of
            {{ listOfAllQuestions.length }}
          </strong>
        </span>
      </div>
      <div class="col m2"></div>
      <div class="col m2 s4" style="display: flex; justify-content: right">
        <button class="waves-effect waves-light btn red darken-3" @click="endSession()">
          <i class="material-icons right">pan_tool</i>END
        </button>
      </div>
    </div>
    <div class="row card-panel">
      <div class="col m10 s10">
        <ul class="tabs">
          <li class="tab" width="10%">
            <a
              :class="[questionClass, questionHighlated]"
              @click="changeStatusOfQuestion()"
              v-if="isQuestionTabVisible"
              >Question</a
            >
          </li>
          <li class="tab" width="10%">
            <a
              :class="[firstConceptClass, firstConceptHighlated]"
              @click="changeStatusOfFirstConcept()"
              v-if="isfirstConceptTabVisible"
            >
              Concept 1
            </a>
          </li>
          <li class="tab" width="10%">
            <a
              :class="[secondConceptClass, secondConceptHighlated]"
              @click="changeStatusOfSecondConcept()"
              v-if="isSecondConceptTabVisible"
            >
              Concept 2
            </a>
          </li>
          <li class="tab" width="10%">
            <a
              :class="[fullSolutionClass, fullSolutionHighlated]"
              @click="fullSolutionClassStatus()"
              v-if="isFullSolutionTabVisible"
            >
              Full Solution
            </a>
          </li>
        </ul>
      </div>
      <div class="m2 s2">
        <div style="display: flex; justify-content: right">
          <button class="iconButton" :class="verticalDotIcon" @click="more_vertIconStatus()">
            <i class="material-icons">more_vert</i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col m8 s8"></div>
        <div class="col m4 s4">
          <div
            class="card-panel dropdown-menu"
            v-if="isDropDownshow"
            v-click-outside="onClickOutside"
          >
            <ul class="dropdown-content">
              <li>
                <i class="material-icons cursor">bookmark</i>
                <a
                  :class="textColorRemoveFromClarification"
                  v-on:mouseover="textColorRemoveFromClarification = 'orange-text'"
                  v-on:mouseleave="textColorRemoveFromClarification = 'black-text'"
                  @click="removeQuestionFromClarificationList()"
                >
                  Remove from Clarification
                </a>
              </li>
              <li>
                <i class="material-icons cursor"> flag </i>
                <a
                  class="right-align cursor"
                  @click="reportQuestion()"
                  :class="reportQuestionTextColor"
                  v-on:mouseover="reportQuestionTextColor = 'orange-text'"
                  v-on:mouseleave="reportQuestionTextColor = 'black-text'"
                  >Report this question</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col s12 m12 imgOfContent">
          <div class="card-image" v-if="questionClass === 'active'">
            <div class="latex" v-if="questionLatexAnswered === 'Y'">
              <p class="custom-p pre-formatted">
                <vue-mathjax :options="VueMathJaxOptions" :formula="questionLatex"></vue-mathjax>
              </p>
            </div>
            <div v-else>
              <img
                :src="questionDiagramUrlForTutorHelpQuestion"
                alt="tutorHelpQuestion"
                height="25%"
                class="responsive-img"
                style="width: 100%"
                draggable="false"
              />
            </div>
          </div>
          <div v-else-if="fullSolutionClass === 'active'" class="card-image">
            <img
              :src="fullSolutionUrl"
              alt="Full Solution"
              height="400px;"
              class="responsive-img"
              style="width: 100%"
            />
          </div>
          <div v-else-if="firstConceptClass === 'active'" class="card-image">
            <img
              :src="listOfkscDiagramUrl[0] ? listOfkscDiagramUrl[0] : ''"
              :alt="listOfkscDiagramUrl[0] ? 'Concept 1' : 'Concept Not Available'"
              height="400px;"
              style="width: 100%"
              class="responsive-img"
            />
          </div>
          <div v-else-if="secondConceptClass === 'active'" class="card-image">
            <img
              :src="listOfkscDiagramUrl[1]"
              alt="Full Solution"
              height="400px;"
              style="width: 100%"
              class="responsive-img"
            />
          </div>
          <div>
            <Modal
              :show="showModal"
              @close="showModal = false"
              class="modalPopup"
              :showHeader="true"
              :showCloseBtn="true"
              :width="'80%'"
            >
              <template v-slot:header></template>
              <template v-slot:body style="overflow-y: auto">
                <div>
                  <strong style="font-size: 30px">What went wrong with the question?</strong>
                  <div>
                    <label>
                      <input class="with-gap" name="reportQuestion" type="radio" />
                      <span>
                        <strong class="black-text">KSC not helpfull</strong>
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input class="with-gap" name="reportQuestion" type="radio" />
                      <span>
                        <strong class="black-text">Answer Tagging Wrong</strong>
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input class="with-gap" name="reportQuestion" type="radio" checked />
                      <span>
                        <strong class="black-text">Mistake in Q and A</strong>
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input class="with-gap" name="reportQuestion" type="radio" />
                      <span>
                        <strong class="black-text">Unclear Image</strong>
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input class="with-gap" name="reportQuestion" type="radio" />
                      <span>
                        <strong class="black-text">Teacher Feedback</strong>
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input class="with-gap" name="reportQuestion" type="radio" />
                      <span>
                        <strong class="black-text">Other Errors</strong>
                      </span>
                    </label>
                  </div>
                  <div class="row">
                    <div class="col s3 m3" style="margin-top: 20px">
                      <button
                        type="button"
                        class="btn waves-effect waves-light"
                        @click="showModal = false"
                      >
                        CANCEL
                      </button>
                    </div>
                    <div class="col s3 m3" style="margin-top: 20px">
                      <button
                        class="btn waves-effect waves-light orange white-text"
                        @click="registerReport()"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </Modal>
          </div>
          <div>
            <Modal
              :show="showErrorModal"
              @close="showErrorModal = false"
              class="modalPopup"
              :showHeader="true"
              :width="'80%'"
              :showCloseBtn="true"
            >
              <template v-slot:header></template>
              <template v-slot:body style="overflow-y: auto">
                <div class="row">
                  <div class="col s4 m4"></div>
                  <div class="col s6 m6">
                    <div>
                      <strong>Error Reported Sucessfully</strong>
                    </div>
                    <div>
                      <img :src="require(`../assets/ThanksIcon.png`)" alt="" />
                    </div>
                  </div>
                </div>
              </template>
            </Modal>
          </div>
          <div class="m2 s2"></div>
          <div class="card-action right-align" style="margin-top: 20px">
            <button
              class="btn blue darken-4"
              @click="
                getPreviousQuestion(
                  indexOfQuestion - 1,
                  listOfAllQuestions[indexOfQuestion - 1].QuestionId,
                )
              "
              v-if="questionNoOfTutorHelp !== 1"
            >
              <i class="material-icons left">arrow_back_ios</i>PREVIOUS
            </button>
            <button
              class="btn blue darken-4 skipButton"
              @click="
                getNextQuestion(
                  indexOfQuestion + 1,
                  listOfAllQuestions[indexOfQuestion + 1].QuestionId,
                )
              "
              v-if="questionNoOfTutorHelp !== listOfAllQuestions.length"
            >
              <i class="material-icons right">arrow_forward_ios</i>NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row card-panel footer">
      <div class="col m4 s6 button-container">
        <div class="btn-block">
          <button
            value="A"
            :class="{ active: selectedAnswer === 'A' }"
            @click="selectAnswer($event)"
          >
            A
          </button>
        </div>
        <div class="btn-block">
          <button
            value="B"
            :class="{ active: selectedAnswer === 'B' }"
            @click="selectAnswer($event)"
          >
            B
          </button>
        </div>
        <div class="btn-block">
          <button
            value="C"
            :class="{ active: selectedAnswer === 'C' }"
            @click="selectAnswer($event)"
          >
            C
          </button>
        </div>
        <div class="btn-block">
          <button
            value="D"
            :class="{ active: selectedAnswer === 'D' }"
            @click="selectAnswer($event)"
          >
            D
          </button>
        </div>
      </div>
      <div class="red-text" v-if="showError">Please Select an answer</div>
      <div class="card-action right-align" style="margin-top: 20px">
        <button
          class="viewButton commonBtnStyle"
          @click="onClickViewConcept()"
          style="margin-right: 20px"
        >
          View Concept
        </button>
        <button type="button" class="viewButton commonBtnStyle" @click="checkAnswer()">
          Check Answer
        </button>
      </div>
    </div>
    <Modal
      :show="showResultPopup"
      @close="showResultPopup = false"
      class="modalPopup"
      :width="'40%'"
      :showCloseBtn="true"
    >
      <template v-slot:body style="overflow-y: auto">
        <div class="modal-content">
          <div v-if="selectedAnswer === answerOption">
            <div class="questionStatus">Correct Answer</div>
            <img
              :src="require(`../assets/CorrectAnswer.png`)"
              alt="Correct Answer Image"
            /><br /><br />
          </div>
          <div v-else>
            <div class="questionStatus">Wrong Answer</div>
            <img :src="require(`../assets/WrongAnswer_icon.png`)" alt="Correct Answer Image" />
            <br />
            Wrong.Option {{ answerOption }} is correct answer.<br /><br />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
// import Vue from "vue";
// import vClickOutside from "v-click-outside";
import { VueMathjax } from "vue-mathjax-next";
import Mobileapi from "../Mobileapi";
import Modal from "../components/Modal.vue";

// Vue.use(vClickOutside);

export default {
  name: "TutorHelpQuestionView",
  data() {
    return {
      listOfTutorHelpQuestions: [],
      courseId: null,
      reloadFlag: false,
      isMouseOnViewButton: false,
      isMouseOnCheckAnswerButton: false,
      isDropDownshow: false,
      questionClass: "active",
      firstConceptClass: "",
      secondConceptClass: "",
      showResultPopup: false,
      firstConceptHighlated: "",
      secondConceptHighlated: "",
      fullSolutionClass: "",
      checkAnswerClass: "",
      textColorQuestion: "purple-text text-darken-4",
      textDarknessQuestion: "text-purple text-darken-4",
      textColorRemoveFromClarification: "black-text",
      questionDiagramUrlForTutorHelpQuestion: "",
      questionLatex: "",
      questionLatexAnswered: "",
      listOfkscDiagramUrl: "",
      fullSolutionUrl: "",
      isQuestionTabVisible: true,
      subjectIdOfSelectedSubject: null,
      topicIdOfSelectedTopic: null,
      chapterIdOfSelectedChapter: null,
      isfirstConceptTabVisible: false,
      isFullSolutionTabVisible: false,
      UserTestSessionId: null,
      nameOfSubjectForViewQuestion: "",
      valueOfQuestionRevisionType: null,
      bookmarkQuestionResponse: "",
      nameOfTopicOfViewQuestion: "",
      nameOfChapterOfViewQuestion: "",
      textDarknessConcept: false,
      listOfAllQuestions: [],
      isSecondConceptTabVisible: false,
      QuestionIdOfTutorHelpQuestion: null,
      showConceptModalPopup: false,
      indexOfQuestion: null,
      isSubjectSelected: false,
      isTopicSelected: false,
      reportQuestionTextColor: "black-text",
      questionNoOfTutorHelp: null,
      userAnswerOption: "",
      listOfQuestionsAfterRemove: [],
      isAnswerOptionSelected: false,
      isShowAnswerValidationMessage: false,
      actualAnswerOptionOfQuestion: "",
      answerOption: "",
      selectedAnswer: "",
      showError: false,
      reportQuestionResponse: "",
      questionHighlated: "selectedTabHighlated",
      conceptHighlated: "",
      fullSolutionHighlated: "",
      valueOfBookMarkLabel: "",
      revisionQuestionId: null,
      radioButtonCheckedOrUnchecked: "",
      chapterIdOfSelectedQuestion: null,
      reportQuestionClass: "",
      showModal: false,
      showErrorModal: false,
      verticalDotIcon: "",
      VueMathJaxOptions: {
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: true,
          processEnvironments: true,
        },
        "HTML-CSS": {
          styles: {
            ".MathJax_Display": {
              margin: 0,
            },
          },
          linebreaks: {
            automatic: true,
          },
        },
      },
    };
  },
  activated() {
    const perfEntries = performance.getEntriesByType("navigation");
    for (let i = 0; i < perfEntries.length; i += 1) {
      const validflag = localStorage.getItem("TutorHelpQuestionView");
      if (validflag !== null) {
        this.reloadFlag = validflag;
      } else {
        this.reloadFlag = false;
        // this.ClearQuestionData();
      }
    }
    if (this.reloadFlag === false) {
      this.QuestionIdOfTutorHelpQuestion = Number(this.$route.params.questionIdOfTutorHelpQuestion);
      this.listOfAllQuestions = JSON.parse(this.$route.params.tutorHelpQuestionsList);
      this.userTestSessionQuestionId = Number(this.$route.params.userTestSessionQuestionId);
      this.subjectIdOfSelectedSubject = Number(this.$route.params.subjectId);
      this.topicIdOfSelectedTopic = Number(this.$route.params.topicId);
      this.chapterIdOfSelectedChapter = Number(this.$route.params.chapterId);
      if (
        this.subjectIdOfSelectedSubject !== -1 &&
        this.topicIdOfSelectedTopic === -1 &&
        this.chapterIdOfSelectedChapter === -1
      ) {
        this.isSubjectSelected = true;
        this.isTopicSelected = false;
        this.isChapterSelected = false;
      }
      if (
        this.subjectIdOfSelectedSubject !== -1 &&
        this.topicIdOfSelectedTopic !== -1 &&
        this.chapterIdOfSelectedChapter === -1
      ) {
        this.isSubjectSelected = false;
        this.isTopicSelected = true;
        this.isChapterSelected = false;
      }
      this.getQuestionInfo();
      this.correctAnswerImage = "";
      this.wrongAnswerImage = "";
      this.questionClass = "active";
      this.firstConceptClass = "";
      this.fullSolutionClass = "";
      this.isAnswerOptionSelected = false;
      this.textDarknessQuestion = "reviseQuestion";
      this.isDropDownshow = false;
      this.questionNoOfTutorHelp = Number(this.$route.params.indexOfQuestion) + 1;

      this.indexOfQuestion = Number(this.$route.params.indexOfQuestion);
      this.nameOfSubjectForViewQuestion = this.$route.params.nameOfSubject;
      this.nameOfTopicOfViewQuestion = this.$route.params.nameOfTopic;
      this.nameOfChapterOfViewQuestion = this.$route.params.nameOfChapter;

      this.isfirstConceptTabVisible = false;
      this.isFullSolutionTabVisible = false;
      this.SourceTypeId = this.$route.params.sourceTypeIdOfQuestion;
      this.showErrorModal = false;
      this.RefershLoad();
    } else {
      this.GetQuestionData();
    }
  },
  components: {
    "vue-mathjax": VueMathjax,
    Modal,
    // Loader: () => import("../components/Loader.vue"),
  },
  methods: {
    getListOfAllMarkForClarificationQuestion() {
      this.$store.dispatch("showLoader", true);
      Mobileapi.getTutorHelpQuestions(
        { courseId: this.courseId },
        (questionResponse) => {
          this.listOfTutorHelpQuestions = questionResponse.data;
          this.$store.dispatch("showLoader", false);
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    beforeDestroy() {
      window.removeEventListener("unload", this.RefershLoad);
    },
    beforeRouteLeave(to, from, next) {
      this.onPageUnload();
      next();
    },
    RefershLoad() {
      const myObj = this.listOfAllQuestions;
      localStorage.setItem("TutorHelpQuestionView", true);
      localStorage.setItem("QuestionIdOfTutorHelpQuestion", this.QuestionIdOfTutorHelpQuestion);
      localStorage.setItem("listOfQuestionsTutor", JSON.stringify(myObj));
      localStorage.setItem("subjectIdOfSelectedSubject", this.subjectIdOfSelectedSubject);
      localStorage.setItem("topicIdOfSelectedTopic", this.topicIdOfSelectedTopic);
      localStorage.setItem("chapterIdOfSelectedChapter", this.chapterIdOfSelectedChapter);
      localStorage.setItem("indexOfQuestion", this.indexOfQuestion);
      localStorage.setItem("nameOfSubjectForViewQuestion", this.nameOfSubjectForViewQuestion);
      localStorage.setItem("nameOfTopicOfViewQuestion", this.nameOfTopicOfViewQuestion);
      localStorage.setItem("nameOfChapterOfViewQuestion", this.nameOfChapterOfViewQuestion);
    },
    GetQuestionData() {
      this.QuestionIdOfTutorHelpQuestion = Number(
        localStorage.getItem("QuestionIdOfTutorHelpQuestion"),
      );
      const listObj = localStorage.getItem("listOfQuestionsTutor");
      this.listOfAllQuestions = JSON.parse(listObj);
      this.indexOfQuestion = Number(localStorage.getItem("indexOfQuestion"));
      this.questionNoOfTutorHelp = this.indexOfQuestion + 1;
      this.questionClass = "active";
      this.getQuestionInfo();
      this.userTestSessionQuestionId =
        this.listOfAllQuestions[this.indexOfQuestion].UserTestSessionQuestionId;
      this.subjectIdOfSelectedSubject = Number(localStorage.getItem("subjectIdOfSelectedSubject"));
      this.topicIdOfSelectedTopic = Number(localStorage.getItem("topicIdOfSelectedTopic"));
      this.chapterIdOfSelectedChapter = Number(localStorage.getItem("chapterIdOfSelectedChapter"));
      if (
        this.subjectIdOfSelectedSubject !== -1 &&
        this.topicIdOfSelectedTopic === -1 &&
        this.chapterIdOfSelectedChapter === -1
      ) {
        this.isSubjectSelected = true;
        this.isTopicSelected = false;
        this.isChapterSelected = false;
      }
      if (
        this.subjectIdOfSelectedSubject !== -1 &&
        this.topicIdOfSelectedTopic !== -1 &&
        this.chapterIdOfSelectedChapter === -1
      ) {
        this.isSubjectSelected = false;
        this.isTopicSelected = true;
        this.isChapterSelected = false;
      }
      this.nameOfSubjectForViewQuestion = localStorage.getItem("nameOfSubjectForViewQuestion");
      this.nameOfTopicOfViewQuestion = localStorage.getItem("nameOfTopicOfViewQuestion");
      this.nameOfChapterOfViewQuestion = localStorage.getItem("nameOfChapterOfViewQuestion");
    },
    clearQuestionData() {
      localStorage.removeItem("TutorHelpQuestionView");
      localStorage.removeItem("QuestionIdOfTutorHelpQuestion");
      localStorage.removeItem("listOfQuestionsTutor");
      localStorage.removeItem("subjectIdOfSelectedSubject");
      localStorage.removeItem("topicIdOfSelectedTopic");
      localStorage.removeItem("chapterIdOfSelectedChapter");
      localStorage.removeItem("indexOfQuestion");
      localStorage.removeItem("nameOfSubjectForViewQuestion");
      localStorage.removeItem("nameOfTopicOfViewQuestion");
      localStorage.removeItem("nameOfChapterOfViewQuestion");
    },

    changestatusOfViewConcept() {
      this.firstConceptClass = "active";
      this.questionClass = "";
      this.fullSolutionClass = "";
      this.textDarknessQuestion = "questionRevision";
      this.conceptHighlated = "selectedTabHighlated";
      this.questionHighlated = "";
      this.fullSolutionHighlated = "";
      this.showConceptModalPopup = true;
      this.kscIndex = 0;
    },
    changeStatusOfQuestion() {
      this.questionClass = "active";
      this.firstConceptClass = "";
      this.fullSolutionClass = "";
      this.questionHighlated = "selectedTabHighlated";
      this.conceptHighlated = "";
      this.fullSolutionHighlated = "";
      this.firstConceptClass = "";
      this.firstConceptHighlated = "";
      this.secondConceptClass = "";
      this.secondConceptHighlated = "";
    },
    fullSolutionClassStatus() {
      this.fullSolutionClass = "active";
      this.questionClass = "";
      this.firstConceptClass = "";
      this.secondConceptClass = "";
      this.textDarknessQuestion = "questionRevision";
      this.textDarknessConcept = "Concept";
      this.fullSolutionHighlated = "selectedTabHighlated";
      this.questionHighlated = "";
      this.conceptHighlated = "";
      this.firstConceptClass = "";
      this.firstConceptHighlated = "";
      this.secondConceptClass = "";
      this.secondConceptHighlated = "";
    },
    reset() {
      this.questionDiagramUrlForTutorHelpQuestion = "";
    },
    onClickViewConcept() {
      this.isfirstConceptTabVisible = true;
      if (this.listOfkscDiagramUrl.length === 2) {
        this.isSecondConceptTabVisible = true;
      }
      this.isFullSolutionTabVisible = true;
      this.questionClass = "";
      this.questionHighlated = "";
      this.fullSolutionClass = "";
      this.firstConceptClass = "active";
      this.firstConceptHighlated = "selectedTabHighlated";
      this.questionHighlated = "";
      this.fullSolutionHighlated = "";
      this.isQuestionTabVisible = true;
    },
    changeStatusOfFirstConcept() {
      this.firstConceptClass = "active";
      this.firstConceptHighlated = "selectedTabHighlated";
      this.secondConceptClass = "";
      this.secondConceptHighlated = "";
      this.questionClass = "";
      this.questionHighlated = "";
      this.fullSolutionClass = "";
      this.fullSolutionHighlated = "";
    },
    changeStatusOfSecondConcept() {
      this.secondConceptClass = "active";
      this.secondConceptHighlated = "selectedTabHighlated";
      this.firstConceptClass = "";
      this.firstConceptHighlated = "";
      this.questionClass = "";
      this.questionHighlated = "";
      this.fullSolutionClass = "";
      this.fullSolutionHighlated = "";
    },
    getNextQuestion(index, questionId) {
      // console.log("index: ", index);
      // console.log("questionId: ", questionId);
      // console.log("2this.listOfAllQuestions: ", this.listOfAllQuestions);
      this.isfirstConceptTabVisible = false;
      this.isSecondConceptTabVisible = false;
      this.isFullSolutionTabVisible = false;
      this.showError = false;
      this.isQuestionTabVisible = true;
      this.isDropDownshow = false;
      this.indexOfQuestion = index;
      this.QuestionIdOfTutorHelpQuestion = questionId;
      this.getQuestionInfo();
      this.questionNoOfTutorHelp += 1;
      this.userTestSessionQuestionId = this.listOfAllQuestions[index].UserTestSessionQuestionId;
      this.selectedAnswer = "";
      this.questionClass = "active";
      this.questionHighlated = "selectedTabHighlated";
      this.nameOfSubjectForViewQuestion = this.listOfAllQuestions[index].SubjectName;
      this.nameOfTopicOfViewQuestion = this.listOfAllQuestions[index].TopicName;
      this.nameOfChapterOfViewQuestion = this.listOfAllQuestions[index].ChapterName;
      this.firstConceptClass = "";
      this.firstConceptHighlated = "";
      this.secondConceptClass = "";
      this.secondConceptHighlated = "";
      this.fullSolutionClass = "";
      this.fullSolutionHighlated = "";
      this.RefershLoad();
    },
    getPreviousQuestion(index, questionId) {
      this.isfirstConceptTabVisible = false;
      this.isSecondConceptTabVisible = false;
      this.isFullSolutionTabVisible = false;
      this.showError = false;
      this.isQuestionTabVisible = true;
      this.questionHighlated = "selectedTabHighlated";
      this.isDropDownshow = false;
      this.indexOfQuestion = index;
      this.QuestionIdOfTutorHelpQuestion = questionId;
      this.getQuestionInfo();
      this.questionNoOfTutorHelp -= 1;
      this.userTestSessionQuestionId = this.listOfAllQuestions[index].UserTestSessionQuestionId;
      this.selectedAnswer = "";
      this.questionClass = "active";
      this.questionHighlated = "selectedTabHighlated";
      this.nameOfSubjectForViewQuestion = this.listOfAllQuestions[index].SubjectName;
      this.nameOfTopicOfViewQuestion = this.listOfAllQuestions[index].TopicName;
      this.nameOfChapterOfViewQuestion = this.listOfAllQuestions[index].ChapterName;
      this.firstConceptClass = "";
      this.firstConceptHighlated = "";
      this.secondConceptClass = "";
      this.secondConceptHighlated = "";
      this.fullSolutionClass = "";
      this.fullSolutionHighlated = "";
      this.RefershLoad();
    },
    endSession() {
      this.clearQuestionData();
      this.$router.push({ name: "TutorHelpNew" });
    },
    more_vertIconStatus() {
      this.isDropDownshow = !this.isDropDownshow;
      this.verticalDotIcon = "white";
    },
    removeQuestionFromClarificationList() {
      Mobileapi.removeMarkForClarificationQuestion(this.userTestSessionQuestionId, (response) => {
        if (response.message === "Success") {
          this.showClarificationModal = true;
          const quesObj = this.listOfAllQuestions.find(
            (item) => item.UserTestSessionQuestionId === this.userTestSessionQuestionId,
          );
          const index = this.listOfAllQuestions.indexOf(quesObj);
          this.QuestionIdOfTutorHelpQuestion = this.listOfAllQuestions[index + 1].QuestionId;
          this.listOfAllQuestions = this.listOfAllQuestions.filter(
            (item) => item.UserTestSessionQuestionId !== this.userTestSessionQuestionId,
          );
          this.getQuestionInfo();
        }
      });
    },
    reportQuestion() {
      this.questionClass = "active";
      this.reportQuestionTextColor = "black-text";
      this.firstConceptClass = "";
      this.fullSolutionClass = "";
      this.isDropDownshow = false;
      this.isfirstConceptTabVisible = true;
      this.isFullSolutionTabVisible = true;
      this.isQuestionTabVisible = true;
      this.showModal = true;
    },
    onClickOutside() {
      if (this.isDropDownshow === true) {
        this.isDropDownshow = false;
      }
    },
    checkAnswer() {
      if (this.selectedAnswer === "") {
        this.showError = true;
      } else {
        this.showError = false;
        this.showResultPopup = true;
      }
    },
    getQuestionInfo() {
      const user = this.$store.getters.user;
      this.$store.dispatch("showLoader", true);
      Mobileapi.getEntireDataOfQuestion(
        {
          QuestionId: this.QuestionIdOfTutorHelpQuestion,
          UserId: user.UserId,
        },
        (response) => {
          this.questionDiagramUrlForTutorHelpQuestion = response.data.QuestionDiagramURL;
          this.$store.dispatch("showLoader", false);
          this.listOfkscDiagramUrl = response.data.KscDiagrams;
          this.fullSolutionUrl = response.data.FullSolutionURL;
          this.answerOption = response.data.AnswerOption;
          this.actualAnswerOptionOfQuestion = response.data.AnswerOption;
          this.questionLatex = response.data.Q_LATEX;
          this.questionLatexAnswered = response.data.LatexStatus;
          this.$store.dispatch("showLoader", false);
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    selectAnswer(e) {
      this.selectedAnswer = e.target.getAttribute("value");
    },
    registerReport() {
      this.questionClass = "active";
      this.firstConceptClass = "";
      this.fullSolutionClass = "";
      this.isDropDownshow = false;
      this.reportQuestionClass = "";
      this.isfirstConceptTabVisible = true;
      this.isFullSolutionTabVisible = true;
      this.isQuestionTabVisible = true;
      this.showErrorModal = true;
      this.showModal = false;
      Mobileapi.reportQuestion(
        {
          userTestSessionQuestionId: this.QuestionId,
          tagId: 3,
          serverToken: localStorage.getItem("SLToken"),
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          const responseOfReportedQuestion = response.data;
          this.reportQuestionResponse = responseOfReportedQuestion;
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
  },
};
</script>
<style scoped>
.questionBox {
  height: 300px;
}

.skipButton {
  margin-left: 1rem;
}

.answerButton {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 20px;
  font-size: 16px;
}

.viewButton {
  border: 1px solid black;
  margin-right: 2px;
}

.checkButton {
  border: 1px solid black;
  margin-right: 5px;
}

.iconButton {
  border-radius: 5px 5px 5px 5px;
}
.reviseQuestion {
  color: purple;
  font-weight: bold;
}
.questionRevise {
  color: purple;
}
.selectedTabHighlated {
  color: purple;
  font-weight: bold;
}
.Concept {
  color: purple;
}
.dropdown-menu {
  position: absolute;
  z-index: 2;
  top: 55px;
  right: 26px;
}
.imgOfContent {
  position: relative;
  z-index: 1;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
}
.modalPopup {
  width: 100%;
}
.cursor {
  cursor: pointer;
}
.card-panel {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  z-index: 2;
  top: 65px;
  background: white;
  right: 26px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}
.btn-block button:hover {
  background-color: blue;
  color: white;
  cursor: pointer;
  background-color: #003b93;
}
.check-btn {
  border: none;
  padding: 10px;
  border-radius: 5px;
}
.check-btn:hover {
  background-color: orange;
  color: white;
}
.footer div .viewButton:hover {
  background-color: orange;
  color: white;
}
.btn-block button {
  padding: 20px;
  border: none;
  border-radius: 5px;
}
.modal-content {
  /* margin-left: 120px; */
  margin: 0 auto;
  width: 200px !important;
}
.btn-block button.active {
  background-color: #003b93;
  color: white;
}
.footer div .viewButton {
  padding: 10px;
  border-radius: 5px;
}
@media screen and (max-width: 600px) {
  .modal-content {
    margin-left: 20px;
  }
  /* .footer div .viewButton{
     word-spacing: no-wrap;
   } */

  /* .footer div .viewButton:after{
     visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Concept";
   } */
}
.questionStatus {
  font-size: 28px;
  font-weight: 600;
}
.latex {
  font-size: 25px;
}
.pre-formatted {
  white-space: pre-wrap; /* 👈 this is the important part */
  /* border: 1px dotted; */
  padding: 1rem;
}
</style>
